<template lang="pug">
  .target-period-row
    .column.target-period
      ExportStatusesTooltip.m-r-10(
        v-if="targetPeriod.$model.id"
        :target-period="targetPeriod"
        @update-period="handleUpdatePeriod"
      )
      TargetPeriod(
        :period="targetPeriod.date_period"
        @update-period="handleUpdatePeriod"
      )
      .target-period-actions
        AppIconButton.remove-period(
          v-if="showRemove"
          icon="trash-alt"
          @click="handleRemovePeriod"
        )
        AppIconButton.add-period(
          v-if="showAdd"
          :class="{ 'no-remove': !showRemove }"
          icon="plus-circle"
          @click="handleAddPeriod"
        )
    .column.plan-name
      AppInput.form-control(
        type="text"
        :class="{ invalid: targetPeriod.plan_name.$error }"
        :value="targetPeriod.plan_name.$model"
        :placeholder="$t('plan_settings.settings_by_period.table_columns.plan_name.placeholder')"
        :title="$t('plan_settings.settings_by_period.table_columns.plan_name.placeholder')"
        @input="handleChangeField('plan_name', $event)"
      )
    .column(
      v-b-tooltip.hover="{ title: notSetForThisOTATooltip, disabled: !isRakutenInputDisabled }"
    )
      AppInput.large.form-control(
        type="text"
        :disabled="isRakutenInputDisabled"
        :value="targetPeriod.plan_name_mobile.$model"
        :placeholder="$t('plan_settings.settings_by_period.table_columns.plan_name_mobile.placeholder')"
        :title="$t('plan_settings.settings_by_period.table_columns.plan_name_mobile.placeholder')"
        @input="handleChangeField('plan_name_mobile', $event)"
      )
    .column.dropdown-column.jalan-points
      AppDropdown.dropdown.jalan-points(
        :disabled="isJalanInputDisabled"
        :class="{ invalid: targetPeriod.jalan_points.$error }"
        v-b-tooltip.hover="{ title: notSetForThisOTATooltip, disabled: !isJalanInputDisabled }"
        close-on-select
        :items="availableJalanPoints"
        :value="selectedJalanPoints"
        @select="handleJalanPointsSelect($event)"
      )
    .column(
      v-b-tooltip.hover="{ title: notSetForThisOTATooltip, disabled: !isRakutenInputDisabled }"
    )
      AppInput.m-r-10.short.form-control(
        type="number"
        :min="0"
        :max="100"
        :value="targetPeriod.rakuten_points.$model"
        :disabled="isRakutenInputDisabled"
        :class="{ invalid: targetPeriod.rakuten_points.$error }"
        @input="handleChangeField('rakuten_points', $event)"
      )
        | %
    .column.plan-code
      AppInput.large.form-control(
        type="text"
        :maxlength="30"
        :value="targetPeriod.plan_code.$model"
        :placeholder="$t('plan_settings.settings_by_period.table_columns.plan_code.placeholder')"
        :title="$t('plan_settings.settings_by_period.table_columns.plan_code.placeholder')"
        @input="handleChangeField('plan_code', $event)"
      )
</template>

<script>
  import { find, set, isNumber } from "lodash-es"

  export default {
    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppInput: () => import("@/components/elements/AppInput"),
      TargetPeriod: () => import("./TargetPeriod"),
      ExportStatusesTooltip: () => import("./ExportStatusesTooltip")
    },

    props: {
      index: {
        type: Number,
        required: true
      },
      jalanPlanId: {
        type: Number
      },
      rakutenPlanId: {
        type: Number
      },
      targetPeriod: {
        type: Object,
        required: true
      },
      showAdd: {
        type: Boolean,
        default: true
      },
      showRemove: {
        type: Boolean,
        default: false
      },
      jalanPoints: {
        type: Array,
        required: true
      }
    },

    computed: {
      availableJalanPoints({ isJalanInputDisabled }) {
        return isJalanInputDisabled ? [] : this.jalanPoints
      },

      selectedJalanPoints() {
        return find(this.jalanPoints, { id: this.targetPeriod.jalan_points.$model })
      },

      isJalanInputDisabled() {
        return !isNumber(this.jalanPlanId)
      },

      isRakutenInputDisabled() {
        return !isNumber(this.rakutenPlanId)
      },

      notSetForThisOTATooltip() {
        return this.$t("plan_settings.settings_by_period.table_columns.no_basic_plan_settings")
      }
    },

    methods: {
      handleUpdatePeriod() {
        set(this.targetPeriod, "_updated.$model", true)
        this.$emit("update-period")
      },

      handleJalanPointsSelect({ id }) {
        set(this.targetPeriod, "jalan_points.$model", id)
        this.handleUpdatePeriod()
      },

      handleChangeField(field, value) {
        set(this.targetPeriod, `${field}.$model`, value)
        this.handleUpdatePeriod()
      },

      handleAddPeriod() {
        this.$emit("add-period")
      },

      handleRemovePeriod() {
        this.$emit("remove-period")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"

  .target-period-row
    display: flex
    flex-direction: row
    align-items: center
    margin-bottom: 0px

    .column
      border: none

      &.plan-name
        input
          width: 170px

      &.plan-code
        margin-left: 10px

      &.jalan-points
        width: 390px

      &.target-period
        display: flex
        flex-direction: row
        min-width: 510px
        align-items: center
        justify-content: left
        padding: 4px 20px
        margin-left: 5px

      .target-period-actions
        display: flex

        .add-period,
        .remove-period
          +icon-button($default-purple)
          margin-left: 5px
          user-select: none

      &.actions
        padding: 5px 10px

        .delete
          border: 0
          border-radius: 50%
          width: 35px
          height: 35px
          fill: $default-purple
          padding: 0 !important

          &:hover
            fill: darken($default-purple, 10%)

          ::v-deep
            svg
              margin-right: 0px

      ::v-deep
        input
          padding: 10px
          border: $element-border
          border-radius: 0.25rem
          height: 2rem
          font-size: 0.8rem

          &:invalid
            +default-invalid-input

          &.large
            min-width: 300px

          &.short
            width: 100px
</style>
